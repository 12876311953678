import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  IconButton,
  Collapse,
  Tooltip,
  TextField,
  Grid,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";

const TableFilter = ({ onApplyFilter }) => {
  const [filterOptions, setFilterOptions] = useState({
    Oficina: [],
    Servicio: [],
    Status: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    Oficina: "",
    Servicio: "",
    Status: "",
    Nombres: "",
    ApellidoPaterno: "",
    ApellidoMaterno: "",
    CURP: "",
    NSS: "",
  });
  const [showFilters, setShowFilters] = useState(false);

  // Llamada a la API para obtener las opciones de los filtros
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const [oficinaResponse, servicioResponse, statusResponse] = await Promise.all([
          axios.get("/select/oficinas",  { withCredentials: true }),
          axios.get("/select/Servicios"),
          axios.get("/select/status"),
        ]);

        setFilterOptions({
          Oficina: oficinaResponse.data,
          Servicio: servicioResponse.data,
          Status: statusResponse.data,
        });
      } catch (error) {
        console.error("Error al obtener opciones de filtro:", error);
      }
    };

    fetchFilterOptions();
  }, []); // Solo se ejecuta una vez al cargar el componente

  const handleFilterChange = (column, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
  };

  const handleApplyFilter = () => {
    onApplyFilter(selectedFilters);
    console.log("Filtros aplicados:", selectedFilters);
  };

  const handleClearFilters = () => {
    const clearedFilters = {
      Oficina: "",
      Servicio: "",
      Status: "",
      Nombres: "",
      ApellidoPaterno: "",
      ApellidoMaterno: "",
      CURP: "",
      NSS: "",
    };
    setSelectedFilters(clearedFilters);
    onApplyFilter(clearedFilters); // Aplicar filtros vacíos
    console.log("Filtros limpiados:", clearedFilters);
  };

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  return (
    <Box>
      {/* Botón para mostrar/ocultar filtros con tooltip */}
      <Tooltip title="Mostrar/Ocultar Filtros" arrow>
        <IconButton color="primary" onClick={toggleFilters}>
          <FilterAltIcon />
        </IconButton>
      </Tooltip>

      {/* Campos de filtro */}
      <Collapse in={showFilters}>
        <Box
          sx={{
            padding: 2,
            border: "1px solid #ccc",
            borderRadius: 2,
            marginTop: 2,
          }}
        >
          <Grid container spacing={2}>
            {/* Campos de texto */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Nombres"
                value={selectedFilters.Nombres}
                onChange={(e) => handleFilterChange("Nombres", e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Apellido Paterno"
                value={selectedFilters.ApellidoPaterno}
                onChange={(e) => handleFilterChange("ApellidoPaterno", e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Apellido Materno"
                value={selectedFilters.ApellidoMaterno}
                onChange={(e) => handleFilterChange("ApellidoMaterno", e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="CURP"
                value={selectedFilters.CURP}
                onChange={(e) => handleFilterChange("CURP", e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="NSS"
                value={selectedFilters.NSS}
                onChange={(e) => handleFilterChange("NSS", e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>

            {/* Select de filtros */}
            {Object.keys(filterOptions).map((column) => (
              <Grid item xs={12} sm={6} md={4} key={column}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel>{column}</InputLabel>
                  <Select
                    value={selectedFilters[column]}
                    onChange={(e) => handleFilterChange(column, e.target.value)}
                    label={column}
                  >
                    {filterOptions[column].map((option) => (
                      <MenuItem
                        key={option.ID}
                        value={column === "Status" ? option.ID : option.Nombre} // Si es Status, usa ID
                      >
                        {option.Nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ))}

            {/* Botones */}
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                <Button variant="contained" color="primary" onClick={handleApplyFilter}>
                  Aplicar Filtros
                </Button>
                <Tooltip title="Limpiar Filtros" arrow>
                  <IconButton color="secondary" onClick={handleClearFilters}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};

export default TableFilter;
