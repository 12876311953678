import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Collapse,
  IconButton,
  Paper,
  TextField,
  Chip,
  Button,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { teal } from "@mui/material/colors";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Link, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";
import axios from "axios";
import StatusSelect from "../components/StatusSelect";
import MotivosSelect from "../components/MotivosSelect";
import ExportButton from "../components/ExportButton";
import NotificacionAsesorModal from "../NotificacionAsesorModal";
import TableFilter from "../components/Filtro";
import RefreshIcon from "@mui/icons-material/Refresh";
import EliminarClienteModal from "../components/Otros/BotonEliminar";
const ClientesTable = () => {
  const [clientes, setClientes] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Nombre");
  const [searchTerm, setSearchTerm] = useState("");
  const [openRows, setOpenRows] = useState({});
  const [loading, setLoading] = useState(true);
  const [niveles, setNiveles] = useState({});
  const [motivos, setMotivos] = useState({});
  const [administrador, setAdministrador] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10); // Paginación con 10 clientes por página
  const [ClientesAMostrar, setClientesAMostrar] = useState([]);
  const [AuxClientes, SetAuxClientes] = useState([]);
  const [AuxClientes2, SetAuxClientes2] = useState([]);
  const [FiltroOCupado, SetFiltroOCupado] = useState(false);

  let sortedClientes = [];
  const navigate = useNavigate();

  const fetchClientes = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/clientes/AllClients", {
        withCredentials: true,
      });
      setClientes(response.data);
      console.log("clientes", response.data);

      // Initialize niveles state with NivelID for each registroID
      const nivelesData = {};
      response.data.forEach((cliente) => {
        nivelesData[cliente.registroID] = cliente.NivelID;
      });
      setNiveles(nivelesData);
      //console.log("nivelesData:", nivelesData);
      const motivoData = {};
      response.data.forEach((cliente) => {
        motivoData[cliente.registroID] = cliente.StatusID;
        /*console.log(
          `RegistroID: ${cliente.registroID}, StatusID: ${cliente.StatusID}`
        );*/
      });
      setMotivos(motivoData);
      //console.log("motivoData:", motivoData);
    } catch (error) {
      //console.error("Error al cargar clientes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientes();
  }, []);

  const handleRefresh = () => {
    fetchClientes(); // Reload the clients data
  };

  useEffect(() => {
    setClientesAMostrar(clientes);
    SetAuxClientes2(clientes);
  }, [clientes]);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await axios.get("/select/admin", {
          withCredentials: true, // Asegúrate de que esto esté correctamente configurado
        });

        // Guardar el administrador en el estado del componente
        setAdministrador(response.data.Permisos);
        //console.log(administrador);
        // Aquí puedes manejar la respuesta
        //console.log("Admin", response.data.Permisos); // Por ejemplo, imprimir los datos en la consola
      } catch (error) {
        //console.error('Error fetching admin data:', error);
      }
    };

    fetchAdminData();
  }, [administrador]); // Dependencias vacías para que se ejecute solo una vez al montar el componente

  const handleStatusChange = async (
    newStatus,
    registroID,
    clienteID,
    servicioID
  ) => {
    //console.log("Status changed to:", newStatus);
    //console.log("Record details:", clienteID, servicioID);

    // Actualizar la UI inmediatamente
    setNiveles((prevNiveles) => ({
      ...prevNiveles,
      [registroID]: newStatus,
    }));

    try {
      await axios.post(
        `/clientes/CambiarStatus/${clienteID}/${servicioID}`,
        { nivel: newStatus },
        { withCredentials: true }
      );

      //console.log("Status cambiado exitosamente:", response.data);

      // Llamar a handleMotivoChange con null si es necesario
      handleMotivoChange(null, registroID, clienteID, servicioID);
    } catch (error) {
      //console.error("Error al cambiar el status:", error);

      // Revertir al estado anterior si es necesario
      setNiveles((prevNiveles) => ({
        ...prevNiveles,
        [registroID]: servicioID === 6 ? 1 : prevNiveles[registroID],
      }));
    }
  };

  const handleMotivoChange = async (
    newMotivo,
    registroID,
    clienteID,
    servicioID
  ) => {
    //console.log("Motivo changed to:", newMotivo);
    //console.log("Record details:", clienteID, servicioID);

    const prevMotivo = motivos[registroID];

    // Actualizar la UI inmediatamente
    setMotivos((prevMotivos) => ({
      ...prevMotivos,
      [registroID]: newMotivo,
    }));

    try {
      await axios.post(
        `/clientes/CambiarMotivo/${clienteID}/${servicioID}`,
        { motivo: newMotivo },
        { withCredentials: true }
      );
      //console.log("Motivo cambiado exitosamente:", response.data);
    } catch (error) {
      //console.error("Error al cambiar el motivo:", error);

      // Revertir el estado si falla
      setMotivos((prevMotivos) => ({
        ...prevMotivos,
        [registroID]: prevMotivo,
      }));
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log(searchTerm.length);
    if (searchTerm.length <= 1) {
      if (FiltroOCupado) {
        setClientesAMostrar(AuxClientes);
      } else {
        setClientesAMostrar(AuxClientes2);
      }
    } else {
      const filteredClientes = ClientesAMostrar.filter((cliente) =>
        [
          cliente.Asesor?.Oficina || "",
          cliente.Nombres || "",
          cliente.ApellidoPaterno || "",
          cliente.ApellidoMaterno || "",
          cliente.NSS || "",
          cliente.CURP || "",
          cliente.ServicioNombre || "",
        ].some(
          (field) =>
            typeof field === "string" &&
            field.toLowerCase().includes(searchTerm.toLowerCase()) // Aquí aplicamos la búsqueda
        )
      );

      sortedClientes = filteredClientes.sort((a, b) => {
        const getComparator = (order, orderBy) => (a, b) => {
          const getValue = (obj, key) =>
            key.includes(".")
              ? key.split(".").reduce((o, i) => o?.[i] || "", obj)
              : obj[key] || "";

          const valueA = getValue(a, orderBy).toString().toLowerCase();
          const valueB = getValue(b, orderBy).toString().toLowerCase();

          if (order === "asc") {
            return valueA.localeCompare(valueB);
          }
          return valueB.localeCompare(valueA);
        };

        // Map `orderBy` to the appropriate keys
        const orderByMap = {
          Oficina: "Asesor.Oficina",
          Nombre: "Nombres",
          "Apellido Paterno": "ApellidoPaterno",
          "Apellido Materno": "ApellidoMaterno",
          CURP: "CURP",
          NSS: "NSS",
          Servicio: "ServicioNombre",
          Status: "NivelID",
        };

        return getComparator(order, orderByMap[orderBy] || orderBy)(a, b);
      });

      setClientesAMostrar(sortedClientes);
    }
  };

  const aplicarFiltro = (filter) => {
    //console.log("filtro", filter);

    // Si todos los filtros están vacíos, mostramos todos los clientes
    if (
      filter.Oficina === "" &&
      filter.Servicio === "" &&
      filter.Status === "" &&
      filter.ApellidoMaterno === "" &&
      filter.ApellidoPaterno === "" &&
      filter.Nombres === "" &&
      filter.NSS === "" &&
      filter.CURP === ""
    ) {
      //console.log("Entro aqui");
      setClientesAMostrar(AuxClientes2);
      SetFiltroOCupado(false);
      return;
    }
    SetFiltroOCupado(true);
    // Obtenemos los filtros activos (valores no vacíos)
    const activeFilters = Object.entries(filter).filter(
      ([key, value]) => value !== "" && value !== null
    );
    console.log("Activos", activeFilters);

    const filteredClientes = AuxClientes2.filter((cliente) =>
      activeFilters.every(([key, value]) => {
        const clientFieldValue = (() => {
          // Mapear el filtro con el campo del cliente correspondiente
          switch (key) {
            case "Oficina":
              return cliente.Asesor?.Oficina || ""; // Oficina es cadena
            case "Servicio":
              return cliente.ServicioNombre || ""; // Servicio es cadena
            case "Status":
              return cliente.NivelID || 0; // NivelID es número
            case "ApellidoMaterno":
              return cliente.ApellidoMaterno || "";
            case "ApellidoPaterno":
              return cliente.ApellidoPaterno || "";
            case "Nombres":
              return cliente.Nombres || "";
            case "NSS":
              return cliente.NSS || "";
            case "CURP":
              return cliente.CURP || "";

            default:
              return "";
          }
        })();

        // Compara números directamente; cadenas se normalizan
        if (typeof value === "number") {
          return clientFieldValue === value; // Comparación para Status
        }
        return (
          clientFieldValue.toString().toLowerCase() ===
          value.toString().toLowerCase()
        );
      })
    );

    // Ordenar los clientes filtrados
    const sortedClientes = filteredClientes.sort((a, b) => {
      const getComparator = (order, orderBy) => (a, b) => {
        const getValue = (obj, key) =>
          key.includes(".")
            ? key.split(".").reduce((o, i) => o?.[i] || "", obj)
            : obj[key] || "";

        const valueA = getValue(a, orderBy).toString().toLowerCase();
        const valueB = getValue(b, orderBy).toString().toLowerCase();

        if (order === "asc") {
          return valueA.localeCompare(valueB);
        }
        return valueB.localeCompare(valueA);
      };

      const orderByMap = {
        Oficina: "Asesor.Oficina",
        Nombre: "Nombres",
        "Apellido Paterno": "ApellidoPaterno",
        "Apellido Materno": "ApellidoMaterno",
        CURP: "CURP",
        NSS: "NSS",
        Servicio: "ServicioNombre",
        Status: "NivelID",
      };

      return getComparator("asc", orderByMap["Oficina"] || "Oficina")(a, b);
    });

    //console.log(sortedClientes);
    setClientesAMostrar(sortedClientes);
    SetAuxClientes(sortedClientes);
    return sortedClientes;
  };

  const handleRowClick = (ClienteID) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [ClienteID]: !prevOpenRows[ClienteID],
    }));
  };

  const handleEditClick = (ClienteID, ServicioID) => {
    navigate(`/NuevoCliente/${ClienteID}/${ServicioID}`);
  };



  const getChipColor = (servicioNombre) => {
    switch (servicioNombre) {
      case "Prospecto":
        return teal[900];
      case "Mejoravit":
        return "#FB8C00";
      case "Infonavit":
        return "#5C6BC0";
      case "Linea2":
        return "#673AB7";
      case "Embarazadas":
        return "#EC407A";
      default:
        return "default";
    }
  };

  const [clienteToNotify, setClienteToNotify] = useState(null); // Estado para almacenar los datos del cliente

  // Función para abrir el modal y pasar los datos del cliente
  const handleNotificar = (cliente) => {
    setClienteToNotify(cliente); // Asigna los datos del cliente al estado
    setIsModalOpen(true); // Abre el modal
  };

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Función para enviar la notificación
  const handleSendNotification = async (
    clienteId,
    servicioId,
    asesorID,
    mensaje
  ) => {
    console.log("Notificación enviada:", {
      clienteId,
      servicioId,
      asesorID,
      mensaje,
    });
    try {
      const response = await axios.post("/select/Notificacion", {
        clienteId,
        servicioId,
        asesorID,
        mensaje,
      });
      //console.log("Respuesta del servidor:", response.data);
      return response.data.message;
    } catch (error) {
      console.error("Error al enviar la notificación:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <Menu />
      <div className="bg-beige container-wrapper">
        <div
          style={{
            paddingBottom: "10px",
            display: "flex",
            alignItems: "center",
            gap: "5px", // Espacio entre elementos
            flexWrap: "wrap", // Adaptable para pantallas más pequeñas
          }}
        >
          {/* Campo de búsqueda */}
          <TextField
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
            style={{
              flexGrow: 1, // Se expande para ocupar espacio restante
              maxWidth: "300px", // Ancho máximo
            }}
          />

          {/* Botón para nuevo cliente */}
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/NuevoCliente"
            style={{
              whiteSpace: "nowrap", // Evita que el texto se corte en pantallas pequeñas
              height: "40px", // Asegura consistencia con el tamaño del filtro
            }}
          >
            Nuevo Cliente
          </Button>

          {/* Componente de filtro */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexGrow: 2, // Asegura buena distribución del espacio
              minWidth: "200px", // Ancho mínimo para pantallas pequeñas
              justifyContent: "flex-start",
            }}
          >
            <Tooltip title="Actualizar Tabla">
              <IconButton color="primary" size="large" onClick={handleRefresh}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <TableFilter
              searchTerm={searchTerm}
              onApplyFilter={aplicarFiltro}
            />
          </div>

          {/* Exportar */}
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <ExportButton
              tableData={ClientesAMostrar}
              style={{
                marginTop: "10px",
                whiteSpace: "nowrap", // Evita el desbordamiento del botón
              }}
            />
          </div>
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }} />
                  {[
                    "Oficina",
                    "Nombre",
                    "Apellido Paterno",
                    "Apellido Materno",
                    "CURP",
                    "NSS",
                    "Servicio",
                    "Status",
                    "Motivo",
                    "Accion",
                  ].map((headCell) => (
                    <TableCell
                      key={headCell}
                      sortDirection={orderBy === headCell ? order : false}
                      onClick={() => handleRequestSort(headCell)}
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        backgroundColor: "#f0f0f0",
                        textAlign: "center",
                        fontSize: "0.875rem", // Tamaño de fuente más pequeño
                      }}
                    >
                      {headCell}
                      {orderBy === headCell ? (
                        order === "asc" ? (
                          <KeyboardArrowUpIcon fontSize="small" />
                        ) : (
                          <KeyboardArrowDownIcon fontSize="small" />
                        )
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ClientesAMostrar.length > 0 ? (
                  ClientesAMostrar.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((cliente) => (
                    <React.Fragment key={cliente.registroID}>
                      <TableRow hover style={{ backgroundColor: "#fafafa" }}>
                        <TableCell style={{ textAlign: "center" }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleRowClick(cliente.ClienteID)}
                          >
                            {openRows[cliente.ClienteID] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell style={{ textAlign: "center" }}>
                          {cliente.Asesor.Oficina}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {cliente.Nombres}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {cliente.ApellidoPaterno}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {cliente.ApellidoMaterno}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {cliente.CURP}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {cliente.NSS}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Chip
                            label={cliente.ServicioNombre}
                            style={{
                              backgroundColor: getChipColor(
                                cliente.ServicioNombre
                              ),
                              color: "white",
                              fontWeight: "bold",
                            }}
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <StatusSelect
                            Admin={administrador}
                            Nivel={niveles[cliente.registroID] || ""}
                            onChange={(newStatus) => {
                              handleStatusChange(
                                newStatus,
                                cliente.registroID,
                                cliente.ClienteID,
                                cliente.ServicioID
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <MotivosSelect
                            ServicioID={cliente.ServicioID}
                            MotivoID={motivos[cliente.registroID] || ""}
                            level={niveles[cliente.registroID] || ""}
                            onChange={(newMotivo) => {
                              handleMotivoChange(
                                newMotivo,
                                cliente.registroID,
                                cliente.ClienteID,
                                cliente.ServicioID
                              );
                            }}
                            Admin={administrador}
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "8px",
                            }}
                          >
                            <Tooltip title="Editar Cliente" arrow>
                              <IconButton
                                onClick={() =>
                                  handleEditClick(
                                    cliente.ClienteID,
                                    cliente.ServicioID
                                  )
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Notificar a asesor" arrow>
                              <IconButton
                                onClick={() => handleNotificar(cliente)}
                              >
                                <NotificationsIcon />
                              </IconButton>
                            </Tooltip>

                            <EliminarClienteModal cliente={cliente} handleRefresh={fetchClientes} />


                            
                          </div>

                          <NotificacionAsesorModal
                            open={isModalOpen}
                            onClose={handleCloseModal}
                            onSend={handleSendNotification}
                            clienteId={clienteToNotify?.ClienteID}
                            servicioId={clienteToNotify?.ServicioID}
                            asesorID={clienteToNotify?.AsesorID}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={8}
                        >
                          <Collapse
                            in={openRows[cliente.ClienteID]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box
                              sx={{
                                padding: 3,
                                backgroundColor: "#f5f5f5",
                                borderRadius: 2,
                              }}
                            >
                              <Typography
                                variant="h5"
                                gutterBottom
                                color="primary"
                              >
                                Detalles del Cliente
                              </Typography>
                              <Box sx={{ marginBottom: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                >
                                  Comentarios:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {cliente.Comentarios}
                                </Typography>
                              </Box>
                              <Box sx={{ marginBottom: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                >
                                  {cliente.ServicioNombre === "Prospecto"
                                    ? "Creado por:"
                                    : "Asignado A:"}

                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    {cliente.Asesor.Nombres}{" "}
                                    {cliente.Asesor.ApellidoPaterno}{" "}
                                    {cliente.Asesor.ApellidoMaterno}
                                  </Typography>
                                </Typography>
                              </Box>

                              <Box sx={{ marginBottom: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                >
                                  Fecha de modificación:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {cliente.updatedAt
                                    ? cliente.updatedAt
                                    : "N/A"}
                                </Typography>
                              </Box>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: "center" }}>
                      No se encontraron clientes.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={clientes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default ClientesTable;
