import React, { useState } from "react";
import { Modal, Box, Typography, Button, IconButton, Tooltip, List, ListItem, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

const EliminarClienteModal = ({ cliente, handleRefresh }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDesactivarProspecto = async () => {
    try {
      console.log("Desactivar prospecto: " + cliente.ClienteID);

      // Llamada a la API
      const response = await axios.post(`/clientes/DesactivarProspecto/${cliente.ClienteID}/${cliente.ServicioID}`, {
        motivo: "Cliente no interesado", // Información adicional si se necesita
      });

      if (response.status === 200) {
        console.log("Prospecto desactivado exitosamente:", response.data);
        handleRefresh(); // Refrescar la lista de clientes
        handleClose(); // Cerrar el modal
      } else {
        console.error("Error al desactivar el prospecto:", response.data.message);
      }
    } catch (error) {
      console.error("Error en la solicitud para desactivar prospecto:", error.message);
      // Mostrar notificación o mensaje de error
    }
  };

  return (
    <>
      <Tooltip
        title={cliente.ServicioID === 6 ? "Desactivar Prospecto" : "Eliminar Cliente"}
        arrow
      >
        <IconButton onClick={handleOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Confirmar Eliminación
          </Typography>

          <Typography id="modal-description" sx={{ mt: 2 }}>
            ¿Estás seguro de que deseas eliminar al siguiente cliente?
          </Typography>

          <Box sx={{ mt: 2 }}>
            <List>
              <ListItem>
                <ListItemText
                  primary="Nombre completo"
                  secondary={`${cliente.Nombres} ${cliente.ApellidoPaterno} ${cliente.ApellidoMaterno}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="NSS" secondary={cliente.NSS} />
              </ListItem>
              <ListItem>
                <ListItemText primary="CURP" secondary={cliente.CURP} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Producto" secondary={cliente.ServicioNombre} />
              </ListItem>
            </List>
          </Box>

          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button variant="contained" color="error" onClick={handleDesactivarProspecto}>
              Eliminar
            </Button>

            <Button variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
          </Box>

          {/* Mensaje condicional */}
          {cliente.ServicioID !== 6 && (
            <Typography
              sx={{ mt: 3, color: "error.main", fontWeight: "bold" }}
              variant="body2"
            >
              La información se eliminará y no se podrá recuperar de ninguna manera. En caso de elimiarlo se activara el prospecto de manera automatica.
            </Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default EliminarClienteModal;
